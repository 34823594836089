import React, { useEffect, useState } from 'react'
import styled, { css } from 'styled-components'
import axios from 'axios'

import Button from 'components/shared/button'
import Icon from 'components/shared/icon'

import { Heading } from 'components/shared/typography'

import media from 'styles/media'

import playIcon from 'assets/icons/play.svg'

import { ActiveRequired } from 'types/shared/active'
import { ImageDataLike } from 'gatsby-plugin-image'
import LazyImage from 'components/shared/lazy-image'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  background: #000;
  &:after {
    padding-top: 56.25%;
    display: block;
    content: '';
  }
  &:hover {
    ${Button} {
      border-color: ${({ theme }) => theme.colors.white};
    }
  }
  video,
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
`

const Thumbnail = styled.div<ActiveRequired>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 2;
  background: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  opacity: 0;
  visibility: hidden;
  ${({ active }) =>
    active &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`

const StyledHeading = styled(Heading)`
  position: absolute;
  top: 50%;
  left: 25%;
  transform: translate(-50%, -50%);

  ${media.lg.max} {
    display: none;
  }
`

const StyledButton = styled(Button)`
  position: absolute;
  pointer-events: none;
  width: 87px;
  height: 87px;

  img {
    max-height: 30px;
  }

  span {
    margin-right: -10%;
  }

  ${media.lg.min} {
    width: 160px;
    height: 160px;

    img {
      max-height: 40px;
    }
  }
`

const ThumbnailImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin-bottom: 0;
`

type Props = {
  videoSrcUrl: string
  videoTitle?: string
  videoThumbnailImage?: {
    src: ImageDataLike
    alt: string
  }
}

const Player: React.FC<Props> = ({
  videoSrcUrl,
  videoTitle,
  videoThumbnailImage,
}) => {
  const [thumbnail, setThumbnail] = useState(true)
  const [thumbnailSrc, setThumbnailSrc] = useState<
    string | { src: ImageDataLike; alt: string }
  >('')

  const handlePlay = () => {
    setThumbnail(false)
  }

  const getYoutubeVideoId = (url: string) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7].length === 11 ? match[7] : false
  }

  const getYoutubeThumbnailSrc = (videoUrl: string) => {
    const youtubeVideoId = getYoutubeVideoId(videoUrl)
    const maxResImgUrl = `https://img.youtube.com/vi_webp/${youtubeVideoId}/maxresdefault.webp`
    const defaultResImgUrl = `https://img.youtube.com/vi_webp/${youtubeVideoId}/0.webp`

    axios
      .get(`https://cors-anywhere.indigital.guru/${maxResImgUrl}`)

      .then((response) => {
        if (response.status === 200) {
          setThumbnailSrc(maxResImgUrl)
        }
      })
      .catch(() => {
        setThumbnailSrc(defaultResImgUrl)
      })
  }

  useEffect(() => {
    if (videoThumbnailImage?.src) {
      setThumbnailSrc(videoThumbnailImage)
    } else {
      getYoutubeThumbnailSrc(videoSrcUrl)
    }
  }, [videoSrcUrl, videoThumbnailImage])

  return (
    <Wrapper>
      <VideoWrapper>
        <Thumbnail
          active={thumbnail}
          onClick={() => {
            handlePlay()
          }}
        >
          {typeof thumbnailSrc === 'string' ? (
            <ThumbnailImage src={thumbnailSrc} alt="miniatura video" />
          ) : (
            <LazyImage
              src={thumbnailSrc.src}
              alt={thumbnailSrc.alt}
              fill
              objectFit="cover"
            />
          )}

          {videoTitle && (
            <StyledHeading
              dangerouslySetInnerHTML={{ __html: videoTitle }}
              as="h4"
              transform="uppercase"
              themecolor="white"
              weight={900}
              line="100%"
              size={53}
            />
          )}
          <StyledButton black round aria-label="Odtwórz film">
            <Icon src={playIcon} auto />
          </StyledButton>
        </Thumbnail>

        {!thumbnail && (
          <iframe
            title="Video"
            src={`${videoSrcUrl}?autoplay=1`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          />
        )}
      </VideoWrapper>
    </Wrapper>
  )
}

export default Player
